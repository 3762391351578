
/* IMPORTS & VARIABLES
----------------------------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&family=Pacifico&display=swap');
@import url('https://css.gg/css');

:root {
  --red-error: #f44250;
  --red-error-rgb: 244, 66, 80;
  --secondary-color: #220626;
  --white: #ffffff;
  --default-border-radius: 4px;
  --default-color: rgba(0, 0, 0, 0.88);
  --default-gray: #949494;
  --default-padding-vertical: 11px;
  --default-padding-horizontal: 12px;
  --default-padding: var(--default-padding-vertical) var(--default-padding-horizontal);
  --color-green: #5EA84A;
  --color-green-active: #267734;
}

/* RESET
----------------------------------------------------------------------------------------*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size:100%;
  vertical-align: baseline;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

/* /ht Ethan Marcotte - http://front.ie/l8rJaA */
img, embed, object, video { max-width: 100%; }
.ie6 img.full, .ie6 object.full, .ie6 embed, .ie6 video { width: 100%; }
  
/* ROOT
----------------------------------------------------------------------------------------*/
html,
body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: var(--default-color);
  background-color: #F6F6F6;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}


#root,
#page {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
}

b {
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-weight: 700;
  margin-top: 0;
}
h1, h2, h3 {
  margin-bottom: 16px;
}
h1 {
  font-size: 36px;
  line-height: 40px;
  
}
h2 {
  font-size: 28px;
  line-height: 32px;
}
h3 {
  font-size: 24px;
  line-height: 28px;
}
h4, h5, h6 {
  margin-bottom: 8px;
}
h4 {
  font-size: 18px;
  line-height: 20px;
}
h5 {
  font-size: 16px;
  line-height: 20px;
}
h6 {
  font-size: 14px;
  line-height: 18px;
}
